import { axiosWithToken, axiosWithoutEncryption } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;
const server3dntechURL = process.env.REACT_APP_3DNTECH_SERVER;

export const setAssetLike = async (id) => {
    const result = await axiosWithToken(`${serverURL}/asset/like/${id}/`, 'post', {});
    return result;
}

export const fetchAssetLike = async (page) => {
    const result = await axiosWithToken(`${serverURL}/asset/like/?page=${page}`, 'get', {});
    return result;
}

export const setAssetDislike = async (id) => {
    const result = await axiosWithToken(`${serverURL}/asset/dislike/${id}/`, 'delete', {});
    return result;
}

export const setAssetWishlist = async (id) => {
    const result = await axiosWithToken(`${serverURL}/asset/wishlist/${id}/`, 'post', {});
    return result;
}

export const fetchAssetWishlist = async (page) => {
    const result = await axiosWithToken(`${serverURL}/asset/wishlist/?page=${page}`, 'get', {});
    return result;
}

export const setAssetDiswishlist = async (id) => {
    const result = await axiosWithToken(`${serverURL}/asset/dis-wishlist/${id}/`, 'delete', {});
    return result;
}

export const uploadAsset = async (data) => {
    const result = await axiosWithToken(`${serverURL}/upload/nft/`, 'post', data);
    return result;
}

export const createMonthAsset = async (data) => {
    const result = await axiosWithToken(`${serverURL}/asset/month-asset/`, 'post', data);
    return result;
}

export const fetchAssets = async (params) => {
    const result = await axiosWithToken(`${serverURL}/asset/`, 'get', {}, params);
    return result;
}

export const fetchAvailableAssets = async () => {
    const result = await axiosWithToken(`${serverURL}/asset/availables`, 'get', {});
    return result;
}

export const fetchAsset = async (assetId) => {
    const result = await axiosWithToken(`${serverURL}/asset/${assetId}`, 'get', {});
    return result;
}

export const deleteAsset = async (id) => {
    const result = await axiosWithToken(`${serverURL}/asset/${id}/`, 'delete', {});
    return result;
}

export const fetchAssetVariable = async () => {
    const result = await axiosWithToken(`${serverURL}/asset/variable/`, 'get', {});
    return result;
}

export const createAsset = async (data) => {
    const result = await axiosWithToken(`${serverURL}/asset/create/`, 'post', data);
    return result;
}

export const updateAsset = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/asset/${id}/`, 'put', data);
    return result;
}

export const create3dntechAsset = async (data) => {
    const result = await axiosWithoutEncryption(`${server3dntechURL}/asset/create/`, 'post', data);
    return result;
}

export const updatePreview = async (data) => {
    const result = await axiosWithoutEncryption(`${server3dntechURL}/asset/preview/`, 'post', data);
    return result;
}